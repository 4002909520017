export type Icon = {
  name: string;
  viewWidth?: number;
  viewHeight?: number;
  paths?: string[];
  pathColors?: string[];
};

export const icons: Icon[] = [
  {
    name: 'search',
    viewWidth: 28,
    viewHeight: 28,
    paths: [
      'M19.25 11.75C19.25 9.07812 17.7969 6.64062 15.5 5.28125C13.1562 3.92188 10.2969 3.92188 8 5.28125C5.65625 6.64062 4.25 9.07812 4.25 11.75C4.25 14.4688 5.65625 16.9062 8 18.2656C10.2969 19.625 13.1562 19.625 15.5 18.2656C17.7969 16.9062 19.25 14.4688 19.25 11.75ZM17.7969 19.4375C16.1094 20.75 14 21.5 11.75 21.5C6.35938 21.5 2 17.1406 2 11.75C2 6.40625 6.35938 2 11.75 2C17.0938 2 21.5 6.40625 21.5 11.75C21.5 14.0469 20.7031 16.1562 19.3906 17.8438L25.6719 24.0781C26.0938 24.5469 26.0938 25.25 25.6719 25.6719C25.2031 26.1406 24.5 26.1406 24.0781 25.6719L17.7969 19.4375Z',
    ],
    pathColors: [],
  },
  {
    name: 'plus_white',
    viewWidth: 12,
    viewHeight: 12,
    paths: [
      'M11.5 5C11.75 5 12 5.25 12 5.5V6.5C12 6.78125 11.75 7 11.5 7H7V11.5C7 11.7812 6.75 12 6.5 12H5.5C5.21875 12 5 11.7812 5 11.5V7H0.5C0.21875 7 0 6.78125 0 6.5V5.5C0 5.25 0.21875 5 0.5 5H5V0.5C5 0.25 5.21875 0 5.5 0H6.5C6.75 0 7 0.25 7 0.5V5H11.5Z',
    ],
    pathColors: [""],
  },
  {
    name: 'plus_disabled_light',
    viewWidth: 12,
    viewHeight: 12,
    paths: [
      'M11.5 5C11.75 5 12 5.25 12 5.5V6.5C12 6.78125 11.75 7 11.5 7H7V11.5C7 11.7812 6.75 12 6.5 12H5.5C5.21875 12 5 11.7812 5 11.5V7H0.5C0.21875 7 0 6.78125 0 6.5V5.5C0 5.25 0.21875 5 0.5 5H5V0.5C5 0.25 5.21875 0 5.5 0H6.5C6.75 0 7 0.25 7 0.5V5H11.5Z',
    ],
    pathColors: [""],
  },
  {
    name: 'cross_circle',
    viewWidth: 18,
    viewHeight: 16,
    paths: [
      'M9 16C6.125 16 3.5 14.5 2.0625 12C0.625 9.53125 0.625 6.5 2.0625 4C3.5 1.53125 6.125 0 9 0C11.8438 0 14.4688 1.53125 15.9062 4C17.3438 6.5 17.3438 9.53125 15.9062 12C14.4688 14.5 11.8438 16 9 16ZM6.46875 5.46875C6.15625 5.78125 6.15625 6.25 6.46875 6.53125L7.9375 8L6.46875 9.46875C6.15625 9.78125 6.15625 10.25 6.46875 10.5312C6.75 10.8438 7.21875 10.8438 7.5 10.5312L8.96875 9.0625L10.4375 10.5312C10.75 10.8438 11.2188 10.8438 11.5 10.5312C11.8125 10.25 11.8125 9.78125 11.5 9.46875L10.0312 8L11.5 6.53125C11.8125 6.25 11.8125 5.78125 11.5 5.46875C11.2188 5.1875 10.75 5.1875 10.4375 5.46875L8.96875 6.9375L7.5 5.46875C7.21875 5.1875 6.75 5.1875 6.46875 5.46875Z',
    ],
    pathColors: [""],
  },
  {
    name: 'plus_big',
    viewWidth: 16,
    viewHeight: 16,
    paths: [
      ' M8.84375 1.78125V7.40625H14.4688C14.9258 7.40625 15.3125 7.79297 15.3125 8.25C15.3125 8.74219 14.9258 9.09375 14.4688 9.09375H8.84375V14.7188C8.84375 15.2109 8.45703 15.5625 8 15.5625C7.50781 15.5625 7.15625 15.2109 7.15625 14.7188V9.09375H1.53125C1.03906 9.09375 0.6875 8.74219 0.6875 8.25C0.6875 7.79297 1.03906 7.40625 1.53125 7.40625H7.15625V1.78125C7.15625 1.32422 7.50781 0.9375 8 0.9375C8.45703 0.9375 8.84375 1.32422 8.84375 1.78125Z',
    ],
    pathColors: [""],
  },
  {
    name: "circle_tickmark_green",
    viewWidth: 16,
    viewHeight: 16,
    paths: [
      "M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8 14.4516C4.43444 14.4516 1.54839 11.5661 1.54839 8C1.54839 4.43444 4.43391 1.54839 8 1.54839C11.5656 1.54839 14.4516 4.43391 14.4516 8C14.4516 11.5656 11.5661 14.4516 8 14.4516ZM12.5205 6.29801L6.95316 11.8207C6.80138 11.9713 6.55629 11.9703 6.40575 11.8185L3.47732 8.8664C3.32674 8.71459 3.32775 8.46947 3.47952 8.31893L4.21239 7.59195C4.36416 7.44141 4.60925 7.44241 4.7598 7.59419L6.68857 9.53862L11.2483 5.01553C11.4001 4.86495 11.6452 4.86593 11.7957 5.0177L12.5227 5.75054C12.6733 5.90231 12.6723 6.14743 12.5205 6.29801Z",
    ],
    pathColors: ["#107F47"],
  },
  {
    name: "warning_icon_orange",
    viewWidth: 18,
    viewHeight: 14,
    paths: [
      "M16.8125 12.0312L10.1562 0.65625C9.875 0.21875 9.4375 0 9 0C8.53125 0 8.09375 0.21875 7.8125 0.65625L1.125 12.0312C0.65625 12.9062 1.28125 14 2.3125 14H15.6562C16.6875 14 17.3125 12.9062 16.8125 12.0312ZM2.625 12.5L8.96875 1.65625L15.3438 12.5H2.625ZM9 9.5625C8.4375 9.5625 8 10 8 10.5312C8 11.0625 8.4375 11.5 9 11.5C9.53125 11.5 9.96875 11.0625 9.96875 10.5312C9.96875 10 9.53125 9.5625 9 9.5625ZM8.25 4.75V7.75C8.25 8.1875 8.5625 8.5 9 8.5C9.40625 8.5 9.75 8.1875 9.75 7.75V4.75C9.75 4.34375 9.40625 4 9 4C8.5625 4 8.25 4.34375 8.25 4.75Z",
    ],
    pathColors: ["#A66400"],
  },
  {
    name: "warning_icon_square",
    viewWidth: 14,
    viewHeight: 14,
    paths: [
      "M7 9.5625C6.4375 9.5625 6 10 6 10.5312C6 11.0625 6.4375 11.5 7 11.5C7.53125 11.5 7.96875 11.0625 7.96875 10.5312C7.96875 10 7.53125 9.5625 7 9.5625ZM7 8.5C7.40625 8.5 7.75 8.1875 7.75 7.75V3.75C7.75 3.34375 7.40625 3 7 3C6.5625 3 6.25 3.34375 6.25 3.75V7.75C6.25 8.1875 6.5625 8.5 7 8.5ZM12 0H2C0.875 0 0 0.90625 0 2V12C0 13.125 0.875 14 2 14H12C13.0938 14 14 13.125 14 12V2C14 0.90625 13.0938 0 12 0ZM12.5 12C12.5 12.2812 12.25 12.5 12 12.5H2C1.71875 12.5 1.5 12.2812 1.5 12V2C1.5 1.75 1.71875 1.5 2 1.5H12C12.25 1.5 12.5 1.75 12.5 2V12Z",
    ],
    pathColors: ["#D91734"],
  },
  {
    name: "selected_tick_circle",
    viewWidth: 18,
    viewHeight: 16,
    paths: [
      "  M9 16C6.125 16 3.5 14.5 2.0625 12C0.625 9.53125 0.625 6.5 2.0625 4C3.5 1.53125 6.125 0 9 0C11.8438 0 14.4688 1.53125 15.9062 4C17.3438 6.5 17.3438 9.53125 15.9062 12C14.4688 14.5 11.8438 16 9 16ZM12.5312 6.53125H12.5C12.8125 6.25 12.8125 5.78125 12.5 5.46875C12.2188 5.1875 11.75 5.1875 11.4688 5.46875L8 8.96875L6.53125 7.5C6.21875 7.1875 5.75 7.1875 5.46875 7.5C5.15625 7.78125 5.15625 8.25 5.46875 8.53125L7.46875 10.5312C7.75 10.8438 8.21875 10.8438 8.53125 10.5312L12.5312 6.53125Z"
    ],
    pathColors: [],
  },
  // Add more icons here...
 
];
